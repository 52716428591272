import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Row, Col, Card, Form } from "react-bootstrap";
import ReactEcharts from "echarts-for-react";
import { verticalBarChartData, pieChartData } from "../../utils/Constants.js";
import "./Company.css";

function CompanyCharts(Props) {
  const { data, newJobData, periodOneQuarter, periodOneYear } = Props;

  const [
    periodOnePieChartDataForLocation,
    setPeriodOnePieChartDataForLocation,
  ] = useState(pieChartData);
  const [
    periodTwoPieChartDataForLocation,
    setPeriodTwoPieChartDataForLocation,
  ] = useState(pieChartData);
  const [periodOnePieChartDataForTitle, setPeriodOnePieChartDataForTitle] =
    useState(pieChartData);
  const [periodTwoPieChartDataForTitle, setPeriodTwoPieChartDataForTitle] =
    useState(pieChartData);
  const [barChartDataforTitles, setBarChartDataforTitles] =
    useState(verticalBarChartData);
  const [barChartDataforLocations, setBarChartDataforLocations] =
    useState(verticalBarChartData);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [displayedLocations, setDisplayedLocations] = useState([]);
  const [selectedTitles, setSelectedTitles] = useState([]);
  const [displayedTitles, setDisplayedTitles] = useState([]);

  const handleLocationChange = (selectedOptions) => {
    const selected = selectedOptions?.map((option) => option?.value);
    setDisplayedLocations(selected);

    if (selected?.length === 0) {
      const allLocations = [
        ...new Set([
          ...(data?.period_1?.locations?.map(
            (location) => location?.job_location
          ) || []),
          ...(data?.period_2?.locations?.map(
            (location) => location?.job_location
          ) || []),
        ]),
      ].slice(0, 30); 
      setSelectedLocations(allLocations);
    } else {
      setSelectedLocations(selected);
    }
  };

  const handleTitleChange = (selectedOptions) => {
    const selected = selectedOptions?.map((option) => option?.value);
    setDisplayedTitles(selected);
    if (selected?.length === 0) {
      const allTitles = [
        ...new Set([
          ...(data?.period_1?.titles?.map((title) => title?.job_title) || []),
          ...(data?.period_2?.titles?.map((title) => title?.job_title) || []),
        ]),
      ].slice(0, 30); 
      setSelectedTitles(allTitles);
    } else {
      setSelectedTitles(selected);
    }
  };

  const transformatedPieChartDataForLocation = () => {
    const transformedData = data?.period_1?.locations
      ?.filter((location) =>
        selectedLocations?.includes(location?.job_location)
      )
      ?.map((location) => ({
        value: location?.jobs,
        name: location?.job_location,
      }));

    const transformedData2 = data?.period_2?.locations
      ?.filter((location) =>
        selectedLocations?.includes(location?.job_location)
      )
      ?.map((location) => ({
        value: location?.jobs,
        name: location?.job_location,
      }));

    setPeriodOnePieChartDataForLocation((prevData) => ({
      ...prevData,
      title: {
        ...prevData,
        text: "Period 1",
      },
      series: [
        {
          ...prevData?.series[0],
          data: transformedData,
        },
      ],
    }));

    setPeriodTwoPieChartDataForLocation((prevData) => ({
      ...prevData,
      title: {
        ...prevData,
        text: "Period 2",
      },
      series: [
        {
          ...prevData?.series[0],
          data: transformedData2,
        },
      ],
    }));
  };

  const transformatedPieChartDataForTitle = () => {
    const transformedData = data?.period_1?.titles
      ?.filter((title) => selectedTitles?.includes(title?.job_title))
      ?.map((title) => ({
        value: title?.jobs,
        name: title?.job_title,
      }));

    const transformedData2 = data?.period_2?.titles
      ?.filter((title) => selectedTitles?.includes(title?.job_title))
      ?.map((title) => ({
        value: title?.jobs,
        name: title?.job_title,
      }));

    setPeriodOnePieChartDataForTitle((prevData) => ({
      ...prevData,
      title: {
        ...prevData,
        text: "Period 1",
      },
      series: [
        {
          ...prevData?.series[0],
          data: transformedData,
        },
      ],
    }));

    setPeriodTwoPieChartDataForTitle((prevData) => ({
      ...prevData,
      title: {
        text: "Period 2",
      },
      series: [
        {
          ...prevData?.series[0],
          data: transformedData2,
        },
      ],
    }));
  };

  const transformatedVerticalBarChartData = () => {
    const transformedData =
      newJobData?.locations?.map((location) => ({
        value: location?.jobs,
        name: location?.job_location,
      })) || [];

    const transformedData2 =
      newJobData?.titles?.map((title) => ({
        value: title?.jobs,
        name: title?.job_title,
      })) || [];

    setBarChartDataforTitles((prevData) => ({
      ...prevData,
      dataset: {
        source: [
          ["Title", ...transformedData2?.map((item) => item?.name)],
          ["Jobs", ...transformedData2?.map((item) => item?.value)],
        ],
      },
      series: transformedData2?.map(() => ({
        type: "bar",
      })),
    }));

    setBarChartDataforLocations((prevData) => ({
      ...prevData,
      dataset: {
        source: [
          ["Locations", ...transformedData?.map((item) => item?.name)],
          ["Jobs", ...transformedData?.map((item) => item?.value)],
        ],
      },
      series: transformedData?.map(() => ({
        type: "bar",
      })),
    }));
  };

  useEffect(() => {
    transformatedPieChartDataForLocation();
    transformatedPieChartDataForTitle();
    transformatedVerticalBarChartData();
  }, [data, newJobData, selectedLocations, selectedTitles]);


  useEffect(() => {
    if (
      data?.period_1?.locations?.length > 0 ||
      data?.period_2?.locations?.length > 0
    ) {
      const allLocations = [
        ...new Set([
          ...(data?.period_1?.locations?.map(
            (location) => location?.job_location
          ) || []),
          ...(data?.period_2?.locations?.map(
            (location) => location?.job_location
          ) || []),
        ]),
      ].slice(0, 30);
      setSelectedLocations(allLocations);
    }

    if (
      data?.period_1?.titles?.length > 0 ||
      data?.period_2?.titles?.length > 0
    ) {
      const allTitles = [
        ...new Set([
          ...(data?.period_1?.titles?.map((title) => title?.job_title) || []),
          ...(data?.period_2?.titles?.map((title) => title?.job_title) || []),
        ]),
      ].slice(0, 30);
      setSelectedTitles(allTitles);
    }
  }, [data]);

  const locationOptions =
    [
      ...new Set([
        ...(data?.period_1?.locations?.map(
          (location) => location?.job_location
        ) || []),
        ...(data?.period_2?.locations?.map(
          (location) => location?.job_location
        ) || []),
      ]),
    ].map((location) => ({
      value: location,
      label: location,
    })) || [];

  const titleOptions =
    [
      ...new Set([
        ...(data?.period_1?.titles?.map((title) => title?.job_title) || []),
        ...(data?.period_2?.titles?.map((title) => title?.job_title) || []),
      ]),
    ].map((title) => ({
      value: title,
      label: title,
    })) || [];

  return (
    <>
      <Card className="p-0 pb-5">
        <div className="m-3" style={{ height: "0px" }}>
          <span className="company-title">Job Distribution by Location</span>
        </div>

        {data?.period_1?.locations?.length <= 0 ||
        data?.period_2?.locations?.length <= 0 ? (
          <div className="text-center p-4">
            <h4 style={{ color: "white", opacity: 0.3 }}>No Data to Compare</h4>
          </div>
        ) : (
          <>
            <div className="location-dropdown mt-4">
              <div className="company-title company-name mt-3">Locations</div>
              <Select
                className="text-muted xs-small m-2"
                size="sm"
                isMulti
                options={locationOptions}
                value={locationOptions?.filter((option) =>
                  displayedLocations?.includes(option.value)
                )}
                placeholder="Select locations"
                onChange={handleLocationChange}
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    minWidth: "200px",
                    backgroundColor: "#32323e",
                  }),
                  singleValue: (provided, state) => ({
                    ...provided,
                    color: "#77778e",
                  }),
                  input: (provided) => ({
                    ...provided,
                    color: "#77778e",
                  }),
                  option: (provided) => ({
                    ...provided,
                    backgroundColor: "#32323e",
                  }),
                }}
              />
            </div>

            <Row className="mt-3">
              <Col lg={6}>
                <ReactEcharts
                  option={periodOnePieChartDataForLocation}
                  style={{ height: "500px", width: "100%" }}
                />
              </Col>
              <Col lg={6}>
                <ReactEcharts
                  option={periodTwoPieChartDataForLocation}
                  style={{ height: "500px", width: "100%" }}
                />
              </Col>
            </Row>
          </>
        )}
      </Card>

      <Card className="p-0 pb-5">
        <div className="m-3">
          <span className="company-title">Job Distribution by Titles</span>
        </div>
        {data?.period_1?.titles?.length <= 0 ||
        data?.period_2?.titles?.length <= 0 ? (
          <div className="text-center">
            <h4 style={{ color: "white", opacity: 0.3 }}>No Data to Compare</h4>
          </div>
        ) : (
          <>
            <div className="location-dropdown mt-4">
              <div className="company-title company-name mt-3">Titles</div>
              <Select
                className="text-muted xs-small m-2"
                size="sm"
                isMulti
                options={titleOptions}
                value={titleOptions?.filter((option) =>
                  displayedTitles?.includes(option.value)
                )}
                placeholder="Select titles"
                onChange={handleTitleChange}
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    minWidth: "200px",
                    backgroundColor: "#32323e",
                  }),
                  singleValue: (provided, state) => ({
                    ...provided,
                    color: "#77778e",
                  }),
                  input: (provided) => ({
                    ...provided,
                    color: "#77778e",
                  }),
                  option: (provided) => ({
                    ...provided,
                    backgroundColor: "#32323e",
                  }),
                }}
              />
            </div>
            <Row className="mt-3">
              <Col lg={6}>
                <ReactEcharts
                  option={periodOnePieChartDataForTitle}
                  style={{ height: "500px", width: "100%" }}
                />
              </Col>
              <Col lg={6}>
                <ReactEcharts
                  option={periodTwoPieChartDataForTitle}
                  style={{ height: "500px", width: "100%" }}
                />
              </Col>
            </Row>
          </>
        )}
      </Card>

      <Card className="p-0">
        <Row>
          <Col lg={6}>
            <div className="m-3">
              <span className="company-title">
                New Jobs available at {data?.company} during ({periodOneQuarter}
                ,{periodOneYear}) with Title
              </span>
            </div>
            {newJobData?.titles?.length <= 0 ||
            newJobData?.titles?.length === undefined ? (
              <div className="text-center p-4">
                <h4 style={{ color: "white", opacity: 0.3 }}>
                  No New Jobs Found
                </h4>
              </div>
            ) : (
              <ReactEcharts
                option={barChartDataforTitles}
                style={{ height: "400px", width: "100%" }}
              />
            )}
          </Col>
          <Col lg={6}>
            <div className="m-3">
              <span className="company-title">
                New Jobs available at {data?.company} during ({periodOneQuarter}
                ,{periodOneYear}) with Location
              </span>
            </div>
            {newJobData?.locations?.length <= 0 ||
            newJobData?.locations?.length === undefined ? (
              <div className="text-center p-4">
                <h4 style={{ color: "white", opacity: 0.3 }}>
                  No New Jobs Found
                </h4>
              </div>
            ) : (
              <ReactEcharts
                option={barChartDataforLocations}
                style={{ height: "400px", width: "100%" }}
              />
            )}
          </Col>
        </Row>
      </Card>
    </>
  );
}

export default CompanyCharts;
