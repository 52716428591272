import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Row, Col, Card } from "react-bootstrap";
import ReactEcharts from "echarts-for-react";
import { pieChartData2 } from "../../utils/Constants.js";
import "./Company.css";

function CompanyComparisonCharts(Props) {
  const { data } = Props;
  const [isTitleJobOpeningsEmpty, setIsTitleJobOpeningsEmpty] = useState(false);
  const [isLocationJobOpeningsEmpty, setIsLocationJobOpeningsEmpty] =
    useState(false);

  const [pieChartData, setPieChartData] = useState({
    title: [],
    location: [],
  });

  const [selectedLocations, setSelectedLocations] = useState([]);
  const [displayedLocations, setDisplayedLocations] = useState([]);
  const [selectedTitles, setSelectedTitles] = useState([]);
  const [displayedTitles, setDisplayedTitles] = useState([]);

  const handleLocationChange = (selectedOptions) => {
    const selected = selectedOptions?.map((option) => option?.value);
    setDisplayedLocations(selected);
    if (selected?.length === 0) {
      const allLocations = data?.data?.reduce((acc, company) => {
        company?.location_jobOpenings &&
          Object.entries(company?.location_jobOpenings)?.forEach(([name]) => {
            if (!acc.includes(name)) {
              acc.push(name);
            }
          });
        return acc;
      }, []);
      setSelectedLocations(allLocations || []);
    } else {
      setSelectedLocations(selected);
    }
  };

  const handleTitleChange = (selectedOptions) => {
    const selected = selectedOptions?.map((option) => option?.value);
    setDisplayedTitles(selected);
    if (selected?.length === 0) {
      const allTitles = data?.data?.reduce((acc, company) => {
        company?.title_jobOpenings &&
          Object.entries(company?.title_jobOpenings)?.forEach(([name]) => {
            if (!acc.includes(name)) {
              acc.push(name);
            }
          });
        return acc;
      }, []);
      setSelectedTitles(allTitles || []);
    } else {
      setSelectedTitles(selected);
    }
  };

  const transformedData = data?.data?.map((company) => {
    const transformedLocations = Object?.entries(company?.location_jobOpenings)
      ?.filter(([name, value]) => selectedLocations?.includes(name))
      ?.slice(0, 30)
      ?.map(([name, value]) => ({
          name,
          value,
      }));
    return {
      company: company?.company,
      locations: transformedLocations,
    };
  });

  const transformedData2 = data?.data?.map((company) => {
    const transformedTitles = Object?.entries(company?.title_jobOpenings)
      ?.filter(([name, value]) => selectedTitles?.includes(name))
      ?.slice(0, 30)
      ?.map(([name, value]) => ({
        name,
        value,
      }));
    return {
      company: company?.company,
      titles: transformedTitles,
    };
  });

  useEffect(() => {
    if (data?.data?.length > 0) {
      const allLocations = data?.data?.reduce((acc, company) => {
        company?.location_jobOpenings &&
          Object.entries(company?.location_jobOpenings)?.forEach(([name]) => {
            if (!acc.includes(name)) {
              acc.push(name);
            }
          });
        return acc;
      }, []);

      setSelectedLocations(allLocations || []);

      const allTitles = data?.data?.reduce((acc, company) => {
        company?.title_jobOpenings &&
          Object.entries(company?.title_jobOpenings)?.forEach(([name]) => {
            if (!acc.includes(name)) {
              acc.push(name);
            }
          });
        return acc;
      }, []);

      setSelectedTitles(allTitles || []);
    }
  }, [data]);

  const updatePieChartData = () => {
    if (transformedData && transformedData2) {
      const updatedLocationData = data?.data?.map((company) => {
        const companyData = transformedData?.find(
          (item) => item.company === company.company
        );
        return {
          ...pieChartData2,
          title: {
            ...pieChartData2.title,
            text: company.company,
          },
          series: [
            {
              ...pieChartData2.series[0],
              data: companyData?.locations || [],
            },
          ],
        };
      });

      const updatedTitleData = data?.data?.map((company) => {
        const companyData = transformedData2?.find(
          (item) => item.company === company.company
        );
        return {
          ...pieChartData2,
          title: {
            ...pieChartData2.title,
            text: company.company,
          },
          series: [
            {
              ...pieChartData2.series[0],
              data: companyData?.titles || [],
            },
          ],
        };
      });

      setPieChartData({
        location: updatedLocationData,
        title: updatedTitleData,
      });
    }
  };

  useEffect(() => {
    updatePieChartData();
    const allTitleEmpty = data?.data?.every(
      (company) => Object.keys(company?.title_jobOpenings).length === 0
    );
    const allLocationEmpty = data?.data?.every(
      (company) => Object.keys(company?.location_jobOpenings).length === 0
    );
    setIsTitleJobOpeningsEmpty(allTitleEmpty);
    setIsLocationJobOpeningsEmpty(allLocationEmpty);
  }, [data, selectedLocations, selectedTitles]);

  const locationsFormatted = data?.data?.reduce((acc, company) => {
    const seenLocations = new Set(acc.map((item) => item.value)); // Track already added Locations
    const companyLocations = Object.entries(
      company?.location_jobOpenings || {}
    ).map(([name]) => ({
      value: name,
      label: name,
    }));

    companyLocations.forEach((location) => {
      if (!seenLocations.has(location.value)) {
        acc.push(location);
        seenLocations.add(location.value);
      }
    });

    return acc;
  }, []);

  const titlesFormatted = data?.data?.reduce((acc, company) => {
    const seenTitles = new Set(acc.map((item) => item.value)); // Track already added titles
    const companyTitles = Object.entries(company?.title_jobOpenings || {}).map(
      ([name]) => ({
        value: name,
        label: name,
      })
    );

    companyTitles.forEach((title) => {
      if (!seenTitles.has(title.value)) {
        acc.push(title);
        seenTitles.add(title.value);
      }
    });

    return acc;
  }, []);

  return (
    <>
      <Card className="p-0">
        <div className="m-3">
          <span className="company-title">
            Company Job Openings Comparison by titles
          </span>
        </div>
        {isTitleJobOpeningsEmpty === true ? (
          <div className="text-center p-4">
            <h4 style={{ color: "white", opacity: 0.3 }}>No Data to Compare</h4>
          </div>
        ) : (
          <>
            <div className="location-dropdown mt-4">
              <div className="company-title company-name mt-3">Titles</div>
              <Select
                className="text-muted xs-small m-2"
                size="sm"
                isMulti
                options={titlesFormatted}
                value={titlesFormatted?.filter((option) =>
                  displayedTitles?.includes(option.value)
                )}
                placeholder="Select titles"
                onChange={handleTitleChange}
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    minWidth: "200px",
                    backgroundColor: "#32323e",
                  }),
                  singleValue: (provided, state) => ({
                    ...provided,
                    color: "#77778e",
                  }),
                  input: (provided) => ({
                    ...provided,
                    color: "#77778e",
                  }),
                  option: (provided) => ({
                    ...provided,
                    backgroundColor: "#32323e",
                  }),
                }}
              />
            </div>
            <Row className="mt-3">
              {pieChartData.title.map((companyChartData, index) => (
                <Col key={index} lg={4}>
                  <ReactEcharts
                    option={companyChartData}
                    style={{ height: "400px", width: "100%" }}
                  />
                </Col>
              ))}
            </Row>
          </>
        )}
      </Card>
      <Card className="p-0">
        <div className="m-3">
          <span className="company-title">
            Company Job Openings Comparison by Locations
          </span>
        </div>
        {isLocationJobOpeningsEmpty === true ? (
          <div className="text-center p-4">
            <h4 style={{ color: "white", opacity: 0.3 }}>No Data to Compare</h4>
          </div>
        ) : (
          <>
            <div className="location-dropdown mt-4">
              <div className="company-title company-name mt-3">Locations</div>
              <Select
                className="text-muted xs-small m-2"
                size="sm"
                isMulti
                options={locationsFormatted}
                value={locationsFormatted?.filter((option) =>
                  displayedLocations?.includes(option.value)
                )}
                placeholder="Select locations"
                onChange={handleLocationChange}
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    minWidth: "200px",
                    backgroundColor: "#32323e",
                  }),
                  singleValue: (provided, state) => ({
                    ...provided,
                    color: "#77778e",
                  }),
                  input: (provided) => ({
                    ...provided,
                    color: "#77778e",
                  }),
                  option: (provided) => ({
                    ...provided,
                    backgroundColor: "#32323e",
                  }),
                }}
              />
            </div>
            <Row className="mt-3">
              {pieChartData.location.map((companyChartData, index) => (
                <Col key={index} lg={4}>
                  <ReactEcharts
                    option={companyChartData}
                    style={{ height: "400px", width: "100%" }}
                  />
                </Col>
              ))}
            </Row>
          </>
        )}
      </Card>
    </>
  );
}

export default CompanyComparisonCharts;
